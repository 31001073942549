.App {
  text-align: center;
  padding: 0;
  margin: 0;
  height:100%;
  min-height: 100vh;
  background: #F7F8FA !important;
  color: #2c2c2c;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 2.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.rootPadding{
  padding-top: 0px;
}
.footerSection{
  background-color:#EFF1F5;
   color:#9B51E0;
}
.footerBody{
  display: inline-flex; 
  padding: 0px 0 15px 0;
  

}
.footerText{
font-family: "Poppins",sans-serif;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 16px;
/* or 160% */

text-align: center;
letter-spacing: -0.2px;

color: #8993A4;
}
.MuiButton-text {
  padding: 6px 0px !important;
}
@media (max-width: 375px){
  .rootPadding{
    padding-top: 0px;
  }
}
@media (max-width: 414px){
  .rootPadding{
    padding-top: 0px;
  }
  .footerBody{
    display: initial; 
    padding: 0px 0 15px 0;
  }
}
.App > div {
  flex: 1 0 auto;
}
footer {
  flex-shrink: 0;
  position: relative;
  z-index: 1250;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #9B51E0;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: grey;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

input::-webkit-contacts-auto-fill-button,input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

/* #pendingList .rdt_TableBody,#permissionList .rdt_TableBody,#alertlistTable .rdt_TableBody,#staffingRevamp .rdt_TableBody{ 
  border: 1px solid #D1D6DD;
} */

.chevron::before {
	border-style: solid;
	border-width: 1.5px 1.5px 0 0;
	content: '';
	display: inline-block;
	height: 14px;
	left: 0.15em;
	position: relative;
	top: 7px;
	transform: rotate(-45deg);
	vertical-align: top;
	width: 14px;
  border-color: #344563;
}

.chevron.right:before {
	left: -0.25em;
	transform: rotate(45deg);
}

.chevron.bottom:before {
	top: 0;
	transform: rotate(135deg);
}

.chevron.left:before {
	left: 0.25em;
	transform: rotate(-135deg);
}

/* #adminUserList .rdt_TableBody,#pendingList .rdt_TableBody{ 
  border: 1px solid #D1D6DD;
} */
