:root{
    --placeholder-color: rgba(0, 0, 0, 0.54);
  }

#textEdit{
    width:100%;

}

.editable{
    padding: 18.5px 14px;
    font-size: 16px;
    text-align: left;
    border:1px solid rgba(0, 0, 0, 0.23);
    border-radius:4px;
    margin: 10px;
    min-height: 125px !important;
}
.editable:focus-visible{
    outline:none;
}

div#draftEditorContainer{
    padding: 18.5px 14px;
    font-size: 16px;
    text-align: left;
    border:1px solid #5E6C84;
    border-radius:10px;
    margin: 10px;
    min-height: 125px !important;
    /* font-family: "Poppins" sans-serif; */
}
/* .dropdownPlaceholder{
    font-size:14px;
    font-family: "Poppins" sans-serif;
} */

#draftEditorContainer:focus-visible{
    outline:none;
}

div#draftEditorContainer a{
    cursor: pointer;
}
 /* #editor:focus{
    border: 2px solid red;
} */

.medium-editor-placeholder::after{
    font-style:normal !important;
    color:var(--placeholder-color) ;
    margin:0 !important;
} 
.linkHover{
    cursor:pointer;
}
.public-DraftEditorPlaceholder-inner{
font-size: 14px;
color: #5E6C84;
font-style: italic;
}
/*#editor{
    padding: 18.5px 14px;
    font-size: 16px;
    text-align: left;
    border:1px solid rgba(0, 0, 0, 0.23) ;
    border-radius: 4px;
}
#editor:focus{
    background-color:red;
    border-color: yellow;
}

#editor:focus-visible{
    border-color: green !important;
}*/