/* roboto-300 - latin */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');  
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&display=swap');
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/roboto-v20-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/roboto-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/roboto-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/roboto-v20-latin-900.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/roboto-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/roboto-v20-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
 margin: 0;
  padding: 0;
    box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .rdt_TableCol {
  font-size: 14px !important;
  padding-right: 10px  !important;
  padding-left: 10px  !important;
  min-width: 156px !important;
}
.rdt_TableCell{
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px  !important;
  padding-right: 10px  !important;
} */
#tabZoneId4{
  width: 1045px !important;
}
#tabZoneId165{
  width: 163px !important;
}
.tab-dashboard>div {
  position: absolute;
  width: 1045px !important;
}
.tab-zone-margin{
  width: 1000px !important;
}
/* .bEChXo{
  padding: 4px 16px 4px 10px !important;
} */
 .makeStyles-drawer-95{
  width: auto;
}
/* .MuiSvgIcon-colorPrimary {
  color: #ffffff !important;
} */ 
.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #ffffff !important;
}
.MuiIconButton-label {
  color: #9b51e0;
}
.MuiPickersDay-daySelected > .MuiIconButton-label{
  color: inherit;
}
.MuiListItem-gutters {
  padding-left: 6px !important;
  padding-right: 0px !important;
}
.MuiListItemIcon-root {
  min-width: 40px !important;
}
.MuiDialog-paperWidthSm {
  max-width: 1200px !important;
}
.canvasjs-chart-container{
  position: relative !important;
}
.canvasjs-chart-canvas{
  position: relative !important;
}
@media (min-width: 600px){
  .makeStyles-drawerClose {
    width: 45px !important;
  }
  .MuiToolbar-gutters {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}
.line-clamp{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;}
/* .sc-fKgIGh, .dJYUvd{
  table-layout: fixed;
} */