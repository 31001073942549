.form-couple {
    display: flex;
    align-items: center;
    margin: 2vh;
    justify-content: space-between;
}

.form-couple-addHospital {
    margin: 6px 2vh;
}

.form-text-field {
    width: 180px;
}
.form-text-field-desc {
    width: 195px;
}

.form-couple-grid {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    row-gap: 2vh;
    column-gap: 2vh;
    margin: 2vh;
}

@media (max-width:635px) and (orientation:portrait) {
    .form-couple{
    margin: 1vh;
    flex-wrap: wrap;
    }
    .form-text-field {
        width: 100%;
    }
    .form-couple-grid{
        display: flex;
        margin: 1vh;
        flex-wrap: wrap;
    }
}

@media (min-width:636px) and (max-width:960px) {
    .form-text-field {
        max-width: 150px;
    }
}

.form-text-field:only-child {
    flex: 1;
}

@media (min-width:636px) and (max-width:960px) {
    .form-text-field-hospital {
        width: 180px;
        max-width: unset;
    }
    .form-text-field-desc {
        width: 195px;
    }
}

.PatienttableContainer .rdt_TableHeader{
    margin-bottom: 0;
}