@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&display=swap);
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/roboto-v20-latin-300.bda729db.eot); /* IE9 Compat Modes */
  src: local(''),
       url(/static/media/roboto-v20-latin-300.bda729db.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/roboto-v20-latin-300.ef7c6637.woff2) format('woff2'), 
       url(/static/media/roboto-v20-latin-300.b00849e0.woff) format('woff'), 
       url(/static/media/roboto-v20-latin-300.806854d4.ttf) format('truetype'), 
       url(/static/media/roboto-v20-latin-300.dd0bea1f.svg#Roboto) format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/roboto-v20-latin-regular.4be1a572.eot); /* IE9 Compat Modes */
  src: local(''),
       url(/static/media/roboto-v20-latin-regular.4be1a572.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/roboto-v20-latin-regular.479970ff.woff2) format('woff2'), 
       url(/static/media/roboto-v20-latin-regular.60fa3c06.woff) format('woff'), 
       url(/static/media/roboto-v20-latin-regular.329ae1c3.ttf) format('truetype'), 
       url(/static/media/roboto-v20-latin-regular.8681f434.svg#Roboto) format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(/static/media/roboto-v20-latin-500.260c8072.eot); /* IE9 Compat Modes */
  src: local(''),
       url(/static/media/roboto-v20-latin-500.260c8072.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/roboto-v20-latin-500.020c97dc.woff2) format('woff2'), 
       url(/static/media/roboto-v20-latin-500.87284894.woff) format('woff'), 
       url(/static/media/roboto-v20-latin-500.8c608256.ttf) format('truetype'), 
       url(/static/media/roboto-v20-latin-500.95204ac9.svg#Roboto) format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/roboto-v20-latin-700.4570b93a.eot); /* IE9 Compat Modes */
  src: local(''),
       url(/static/media/roboto-v20-latin-700.4570b93a.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/roboto-v20-latin-700.2735a3a6.woff2) format('woff2'), 
       url(/static/media/roboto-v20-latin-700.adcde98f.woff) format('woff'), 
       url(/static/media/roboto-v20-latin-700.96559ffb.ttf) format('truetype'), 
       url(/static/media/roboto-v20-latin-700.57888be7.svg#Roboto) format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/roboto-v20-latin-900.352cf1dd.eot); /* IE9 Compat Modes */
  src: local(''),
       url(/static/media/roboto-v20-latin-900.352cf1dd.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/roboto-v20-latin-900.9b3766ef.woff2) format('woff2'), 
       url(/static/media/roboto-v20-latin-900.bb1e4dc6.woff) format('woff'), 
       url(/static/media/roboto-v20-latin-900.22acb397.ttf) format('truetype'), 
       url(/static/media/roboto-v20-latin-900.9c4bedee.svg#Roboto) format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
 margin: 0;
  padding: 0;
    box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .rdt_TableCol {
  font-size: 14px !important;
  padding-right: 10px  !important;
  padding-left: 10px  !important;
  min-width: 156px !important;
}
.rdt_TableCell{
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px  !important;
  padding-right: 10px  !important;
} */
#tabZoneId4{
  width: 1045px !important;
}
#tabZoneId165{
  width: 163px !important;
}
.tab-dashboard>div {
  position: absolute;
  width: 1045px !important;
}
.tab-zone-margin{
  width: 1000px !important;
}
/* .bEChXo{
  padding: 4px 16px 4px 10px !important;
} */
 .makeStyles-drawer-95{
  width: auto;
}
/* .MuiSvgIcon-colorPrimary {
  color: #ffffff !important;
} */ 
.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #ffffff !important;
}
.MuiIconButton-label {
  color: #9b51e0;
}
.MuiPickersDay-daySelected > .MuiIconButton-label{
  color: inherit;
}
.MuiListItem-gutters {
  padding-left: 6px !important;
  padding-right: 0px !important;
}
.MuiListItemIcon-root {
  min-width: 40px !important;
}
.MuiDialog-paperWidthSm {
  max-width: 1200px !important;
}
.canvasjs-chart-container{
  position: relative !important;
}
.canvasjs-chart-canvas{
  position: relative !important;
}
@media (min-width: 600px){
  .makeStyles-drawerClose {
    width: 45px !important;
  }
  .MuiToolbar-gutters {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}
.line-clamp{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;}
/* .sc-fKgIGh, .dJYUvd{
  table-layout: fixed;
} */
.App {
  text-align: center;
  padding: 0;
  margin: 0;
  height:100%;
  min-height: 100vh;
  background: #F7F8FA !important;
  color: #2c2c2c;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 2.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.rootPadding{
  padding-top: 0px;
}
.footerSection{
  background-color:#EFF1F5;
   color:#9B51E0;
}
.footerBody{
  display: inline-flex; 
  padding: 0px 0 15px 0;
  

}
.footerText{
font-family: "Poppins",sans-serif;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 16px;
/* or 160% */

text-align: center;
letter-spacing: -0.2px;

color: #8993A4;
}
.MuiButton-text {
  padding: 6px 0px !important;
}
@media (max-width: 375px){
  .rootPadding{
    padding-top: 0px;
  }
}
@media (max-width: 414px){
  .rootPadding{
    padding-top: 0px;
  }
  .footerBody{
    display: inline;
    display: initial; 
    padding: 0px 0 15px 0;
  }
}
.App > div {
  flex: 1 0 auto;
}
footer {
  flex-shrink: 0;
  position: relative;
  z-index: 1250;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #9B51E0;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: grey;
  border-radius: 50%;
  -webkit-animation: lds-default 1.2s linear infinite;
          animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@-webkit-keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

input::-webkit-contacts-auto-fill-button,input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

/* #pendingList .rdt_TableBody,#permissionList .rdt_TableBody,#alertlistTable .rdt_TableBody,#staffingRevamp .rdt_TableBody{ 
  border: 1px solid #D1D6DD;
} */

.chevron::before {
	border-style: solid;
	border-width: 1.5px 1.5px 0 0;
	content: '';
	display: inline-block;
	height: 14px;
	left: 0.15em;
	position: relative;
	top: 7px;
	transform: rotate(-45deg);
	vertical-align: top;
	width: 14px;
  border-color: #344563;
}

.chevron.right:before {
	left: -0.25em;
	transform: rotate(45deg);
}

.chevron.bottom:before {
	top: 0;
	transform: rotate(135deg);
}

.chevron.left:before {
	left: 0.25em;
	transform: rotate(-135deg);
}

/* #adminUserList .rdt_TableBody,#pendingList .rdt_TableBody{ 
  border: 1px solid #D1D6DD;
} */

.hrline {
    width: 80%;
    display: flex; 
    flex-direction: row;
    align-self: center;
    font-size: 18px;
    color: #808080;
    margin-bottom: 20px;
}

.hrline::before,.hrline::after{
    content: ""; 
    flex: 1 1; 
    border-bottom: 2px solid #808080; 
    margin: auto;
}
.form-couple {
    display: flex;
    align-items: center;
    margin: 2vh;
    justify-content: space-between;
}

.form-couple-addHospital {
    margin: 6px 2vh;
}

.form-text-field {
    width: 180px;
}
.form-text-field-desc {
    width: 195px;
}

.form-couple-grid {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2vh;
    row-gap: 2vh;
    grid-column-gap: 2vh;
    -webkit-column-gap: 2vh;
            column-gap: 2vh;
    margin: 2vh;
}

@media (max-width:635px) and (orientation:portrait) {
    .form-couple{
    margin: 1vh;
    flex-wrap: wrap;
    }
    .form-text-field {
        width: 100%;
    }
    .form-couple-grid{
        display: flex;
        margin: 1vh;
        flex-wrap: wrap;
    }
}

@media (min-width:636px) and (max-width:960px) {
    .form-text-field {
        max-width: 150px;
    }
}

.form-text-field:only-child {
    flex: 1 1;
}

@media (min-width:636px) and (max-width:960px) {
    .form-text-field-hospital {
        width: 180px;
        max-width: unset;
    }
    .form-text-field-desc {
        width: 195px;
    }
}

.PatienttableContainer .rdt_TableHeader{
    margin-bottom: 0;
}
:root{
    --placeholder-color: rgba(0, 0, 0, 0.54);
  }

#textEdit{
    width:100%;

}

.editable{
    padding: 18.5px 14px;
    font-size: 16px;
    text-align: left;
    border:1px solid rgba(0, 0, 0, 0.23);
    border-radius:4px;
    margin: 10px;
    min-height: 125px !important;
}
.editable:focus-visible{
    outline:none;
}

div#draftEditorContainer{
    padding: 18.5px 14px;
    font-size: 16px;
    text-align: left;
    border:1px solid #5E6C84;
    border-radius:10px;
    margin: 10px;
    min-height: 125px !important;
    /* font-family: "Poppins" sans-serif; */
}
/* .dropdownPlaceholder{
    font-size:14px;
    font-family: "Poppins" sans-serif;
} */

#draftEditorContainer:focus-visible{
    outline:none;
}

div#draftEditorContainer a{
    cursor: pointer;
}
 /* #editor:focus{
    border: 2px solid red;
} */

.medium-editor-placeholder::after{
    font-style:normal !important;
    color:rgba(0, 0, 0, 0.54);
    color:var(--placeholder-color) ;
    margin:0 !important;
} 
.linkHover{
    cursor:pointer;
}
.public-DraftEditorPlaceholder-inner{
font-size: 14px;
color: #5E6C84;
font-style: italic;
}
/*#editor{
    padding: 18.5px 14px;
    font-size: 16px;
    text-align: left;
    border:1px solid rgba(0, 0, 0, 0.23) ;
    border-radius: 4px;
}
#editor:focus{
    background-color:red;
    border-color: yellow;
}

#editor:focus-visible{
    border-color: green !important;
}*/
